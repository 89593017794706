
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'position': relative,
    )
  );
}

.fluid {
  @include input;

  @include desktop(
    (
      'position': relative,

      'display': flex,
      'align-items': center,

      'cursor': pointer,
    )
  );

  svg {
    @include desktop(
      (
        'position': absolute,

        'top': 50%,
        'right': 20px,

        'width': 10px,
        'height': 5px,

        'transform': translateY(-50%),
        'fill': get-theme-color('primary'),
      )
    );
  }
}

.droplist {
  @include desktop(
    (
      'top': 100%,

      'left': 0,
      'right': 0,
    )
  );
}

.search {
  @include desktop(
    (
      'display': flex,
    )
  );

  :global(input) {
    @include desktop(
      (
        'padding': 10px,
        'margin': 0 16px 10px,

        'display': block,

        'width': 100%,
        'box-sizing': border-box,

        'border': 2px solid get-theme-color('input'),
        'border-radius': $input-radius,
      )
    );
  }
}
