
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include card;
}

.title {
  @include h1;
}

.features {
  @include desktop(
    (
      'display': flex,
      'margin': 0 -16px,
    )
  );

  @include mobile(
    (
      'display': block,
    )
  );
}

.feature {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'margin': 16px 16px 0,
    )
  );

  &__icon {
    @include desktop(
      (
        'width': 32px,
        'height': 32px,

        'margin-right': 12px,
      )
    );
  }
}

.info {
  &__title {
    @include h2;
  }
}

.shedule {
  @include list;

  @include desktop(
    (
      'display': flex,
      'flex-wrap': wrap,

      'margin': 8px -16px 0,
    )
  );

  &__item {
    @include desktop(
      (
        'margin': 8px 16px 0,
      )
    );
  }

  &__title {
    @include h2;

    @include desktop(
      (
        'margin-top': 16px,
      )
    );
  }
}

.comment {
  @include desktop(
    (
      'margin-top': 16px,
    )
  );
}

.list {
  @include list;

  @include desktop(
    (
      'display': flex,
      'flex-wrap': wrap,

      'margin': 8px -16px 0,
    )
  );

  &__item {
    @include desktop(
      (
        'margin': 8px 16px 0,
      )
    );
  }
}
