
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'flex-shrink': 0,
      'align-self': flex-start,

      'background': get-theme-color('primary'),
      'border-radius': 50%,

      'text-align': center,
    )
  );

  svg {
    @include desktop(
      (
        'height': 100%,
        'fill': #fff,
      )
    );
  }
}
