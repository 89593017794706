
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.separator {
  @include desktop(
    (
      'width': 100%,
      'height': 1px,
      'background': get-theme-color('bg'),
    )
  );
}
