
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
input {
  border: none;
  box-shadow: none;
  outline: none;
}

.fluid {
  @include input;
}
