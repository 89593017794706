
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.title {
  @include h2;
}

.row {
  @include desktop(
    (
      'position': relative,

      'display': flex,
      'padding': 16px 24px,
    )
  );

  &_heading {
    font-weight: 700;
  }

  &:nth-child(odd) {
    background: get-theme-color('bg');
  }
}

.table {
  @include desktop(
    (
      'margin-top': 16px,
    )
  );
}

.dotted {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'justify-content': space-between,

      'margin-left': auto,
      'padding': 0,

      'width': 20px,
      'height': 20px,
    )
  );

  span {
    @include desktop(
      (
        'display': block,

        'width': 4px,
        'height': 4px,

        'background': get-theme-color('default'),
        'border-radius': 50%,

        'transition': inherit,
      )
    );
  }

  &:hover {
    span {
      background: get-theme-color('primary');
    }
  }
}

.droplist {
  @include desktop(
    (
      'position': absolute,

      'right': 24px,
      'top': 34px,
    )
  );
}
