
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 70px;  
  

  position: absolute;
  top: 0px;
  
  z-index: 2;
  display: flex;
  flex-direction: column;

  @include desktop(
    (
      
      'top': 0px,
      'width': 35%
    )
  );

  @include mobile(
    (
      'width': 70%,
      'left': 47px,
           
    )
  );

}

.lists {
  background-color: #ffdb4d;
}
.title {
  margin: 5px;
}

.list {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 3px;
  margin-top: 3px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button {
  background-color: #ffdb4d;
  padding: 0px 20px;
  width: 120px;
  height: 26px;
}

.input {
  margin-bottom: 5px;

  width: 100%;
  height: 26px;
}
