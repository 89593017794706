
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'display': flex,

      'margin': 0 -15px,
    )
  );
}

.button {
  @include desktop(
    (
      'margin': 0 15px,
    )
  );

  &:nth-child(1) {
    @include link;
  }

  &:nth-child(2) {
    @include link(get-theme-color('error'));
  }
}
