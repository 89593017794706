
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
button {
  padding: 0;

  border: none;
  box-shadow: none;
  background: none;

  font: inherit;

  transition: $transition-time;

  cursor: pointer;
}

.button {
  @include desktop(
    (
      'display': inline-block,
      'height': 60px,

      'background': get-theme-color('primary'),
      'border-radius': $button-radius,

      'font-size': 14px,
      'font-weight': 700,
      'color': #fff,

      'text-align': center,
    )
  );

  &:hover {
    background: darken(get-theme-color('primary'), 5%);
  }

  &:global._light {
    background: get-theme-color('tabs-bg');
    color: get-theme-color('tabs-text');

    &:hover {
      background: darken(get-theme-color('tabs-bg'), 3%);
    }
  }
}

.inner {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'justify-content': center,

      'padding': 10px 32px,
      'height': 100%,
    )
  );
}
