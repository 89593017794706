
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.slider-arrow {
  @include desktop(
    (
      'width': 60px,
      'height': 60px,

      'background': get-theme-color('primary'),
      'border-radius': 50%,

      'z-index': 5,
    )
  );

  svg {
    @include desktop(
      (
        'position': relative,
        'left': -2px,

        'width': 11px,
        'height': 100%,

        'fill': #fff,
      )
    );
  }

  &[data-direction='next'] {
    @include desktop(
      (
        'transform': scaleX(-1),
      )
    );
  }
}
