
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.input {
  margin: 0px 10px 0px 0px;
}
