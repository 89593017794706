
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  :global .separator {
    @include desktop(
      (
        'margin': 24px 0,
      )
    );
  }
}

.title {
  @include h3;

  @include desktop(
    (
      'margin-bottom': 12px,
    )
  );

  &:last-child {
    margin-bottom: 0;
  }
}

.filter {
  @include desktop(
    (
      'display': flex,
      'flex-wrap': wrap,
      'margin': -4px -2px 0,
    )
  );
  &__item {
    @include desktop(
      (
        'display': block,

        'margin': 4px 2px 0,
        'padding': 8px 16px,

        'background': get-theme-color('input'),
        'border-radius': $button-radius,

        'color': get-theme-color('tabs-text'),
        'font-weight': 700,

        'transition': $transition-time,
      )
    );

    &:global.active {
      background: get-theme-color('primary');
      color: #fff;
    }
  }
}

.radiusButtons {
  @include desktop(
    (
      'margin': 16px -16px 0,

      'display': flex,
    )
  );

  button {
    @include desktop(
      (
        'margin': 0 16px,
      )
    );

    &:nth-child(1) {
      @include link;
    }

    &:nth-child(2) {
      @include link(get-theme-color('error'));
    }
  }
}
