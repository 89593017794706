
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.card {
  @include card;

  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}

.title {
  @include h2;

  @include desktop(
    (
      'margin-bottom': 16px,
    )
  );
}

.social {
  @include desktop(
    (
      'display': flex,
      'flex-wrap': wrap,
      'margin': -16px -16px 0,
    )
  );

  @include mobile(
    (
      'margin': 16px -4px,
    )
  );

  &__item {
    @include desktop(
      (
        'display': flex,
        'align-items': center,

        'margin': 16px 16px 0,

        'border-radius': 50%,

        'text-align': center,
        'color': inherit,
      )
    );

    @include mobile(
      (
        'margin': 0 4px,
      )
    );
  }

  &__text {
    @include desktop(
      (
        'margin-left': 8px,
        'padding-bottom': 2px,

        'border-bottom': 1px solid rgba(get-theme-color('default'), 0.2),
        'font-size': 12px,
      )
    );

    @include mobile(
      (
        'display': none,
      )
    );
  }

  &__icon {
    @include desktop(
      (
        'flex-shrink': 0,

        'width': 40px,
        'height': 40px,

        'border-radius': 50%,

        'text-align': center,
      )
    );

    svg {
      @include desktop(
        (
          'width': 14px,
          'height': 100%,

          'fill': #fff,
        )
      );
    }

    &_whatsapp {
      background: get-theme-gradient('whatsapp');
    }

    &_telegram {
      background: get-theme-gradient('telegram');
    }

    &_viber {
      background: get-theme-gradient('viber');
    }
  }
}
