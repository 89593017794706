
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'margin': 32px 0,
    )
  );
}

.title {
  @include h3;

  @include desktop(
    (
      'margin-bottom': 16px,
    )
  );
}

.row {
  @include desktop(
    (
      'margin-bottom': -16px,
    )
  );

  :global(> *) {
    @include desktop(
      (
        'margin-bottom': 16px,
      )
    );
  }
}

.button {
  @include link();

  @include desktop(
    (
      'margin-top': 16px,
      'max-width': fit-content,

      'font-size': 12px,
    )
  );
}
