
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.info {
  @include card;

  @include desktop(
    (
      'position': relative,

      'margin-top': 20px,
    )
  );
}

.top {
  @include desktop(
    (
      'display': flex,
      'align-items': flex-start,
      'justify-content': space-between,
    )
  );
}

.title {
  @include h2;
  margin-right: auto;
}

.clear {
  @include link(get-theme-color('error'));
}

.short {
  @include link;

  @include desktop(
    (
      margin-right: 20px,
    )
  );
}

.url {
  @include desktop(
    (
      'margin-top': 16px,

      'display': flex,
      'align-items': center,
    )
  );

  &__copy {
    $self: &;

    @include desktop(
      (
        'flex-shrink': 0,
        'margin-right': 10px,
        'overflow': hidden,

        'width': 30px,
        'height': 30px,

        'background': get-theme-color('input'),
        'border-radius': 50%,
      )
    );

    svg {
      @include desktop(
        (
          'width': 15px,
          'height': 100%,

          'fill': get-theme-color('tabs-text'),
          'transition': inherit,
        )
      );
    }

    &:hover:not(#{$self}_success) {
      background: get-theme-color('primary');

      svg {
        fill: #fff;
      }
    }

    &_success {
      background: get-theme-color('success');

      svg {
        fill: #fff;
      }
    }
  }

  &__link {
    @include desktop(
      (
        'width': calc(100% - 40px),
      )
    );

    a {
      @include link;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
      // color: inherit;
      // text-decoration: underline;
      // &::before {
      // display: none;
      // }
    }
  }
}

.card {
  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}
