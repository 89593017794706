
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.list {
}

.listItem {
  @include desktop(
    (
      'position': relative,
      'padding-left': 18px,
    )
  );

  &:before {
    @include desktop(
      (
        'position': absolute,
        'content': '',

        'top': 6px,
        'left': 0,

        'width': 6px,
        'height': 6px,

        'background': get-theme-color('primary'),
        'border-radius': 50%,
      )
    );
  }
}
