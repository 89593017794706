
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@import 'helpers/variables.scss';
@import 'helpers/functions.scss';
@import 'helpers/mixins.scss';

@import 'base/base.scss';

@import 'pages/RestaurantPage.scss';

.leaflet-attribution-flag {
    display: none !important;
}

.leaflet-right .leaflet-routing-container.leaflet-routing-container-hide {
    display: none;
}
