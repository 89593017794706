
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include fl-reset;
  @include card;

  @include desktop(
    (
      'position': relative,
    )
  );
}

.title {
  @include h2;
}

.removeButton {
  @include link(get-theme-color('error'));

  @include desktop(
    (
      'position': absolute,

      'top': 40px,
      'right': 40px,
    )
  );
}

.actions {
  @include desktop(
    (
      'margin-top': 32px,

      'display': flex,
      'align-items': flex-start,
    )
  );
}
