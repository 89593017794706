.search {
  position: absolute;
  z-index: 2;
  background-color: aliceblue;
  padding: 5px;
  border-radius: 10px;
  width: 400px;
  border: 1px solid rgb(217, 217, 217);
}

.legend {
   position: absolute;
   z-index: 2;
   background-color: aliceblue;
   padding: 5px;
   border-radius: 10px;
   width: 300px;
   border: 1px solid rgb(217, 217, 217);
   bottom: 40px;
 }