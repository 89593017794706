
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.offset {
  @media (max-width: 2280px) { ///1660px
    @include desktop(
      (
        'padding-left': 230px,
      )
    );
  }
}

.card {
  @include card;
}

.header {
  @include desktop(
    (
      'padding-top': 20px,
      'padding-bottom': 20px,
      'margin-bottom': 5px,

      'background': #fff,
    )
  );
}

.row {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'justify-content': space-between,
      'padding': 0px 40px,
    )
  );
}

.button {
  @include desktop(
    (
      'height': 40px,

      'font-size': 12px,
    )
  );
}

.forbidden {
  @include card;
  @include h1;

  @include desktop(
    (
      'text-align': center,
    )
  );

  &:first-child {
    @include desktop(
      (
        'margin-top': 40px,
      )
    );
  }
}

.navigation {
  @include desktop(
    (
      'position': fixed,

      'top': 0,
      'bottom': 0,
      'left': 0,

      'width': 230px,

      'background': get-theme-color('bg-dark'),
    )
  );

  &__item {
    $self: &;

    @include desktop(
      (
        'display': flex,
        'align-items': center,

        'padding': 10px 20px,

        'color': #fff,
      )
    );

    &:first-child {
      @include desktop(
        (
          'margin-top': 40px,
        )
      );
    }

    &:hover:not(#{$self}_active) {
      background: rgba(#fff, 0.2);
    }

    &_active {
      background: get-theme-color('primary');
    }

    &Icon {
      @include desktop(
        (
          'width': 30px,
          'height': 24px,

          'margin-right': 15px,
        )
      );

      svg {
        @include desktop(
          (
            'max-width': 100%,
            'max-height': 100%,

            'fill': #fff,
          )
        );
      }
    }
  }
}
