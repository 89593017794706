
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include card;

  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}

.title {
  @include h1;
}

.slider {
  @include desktop(
    (
      'margin-top': 32px,
    )
  );

  :global .swiper {
    @include desktop(
      (
        'margin': 0 -20px,
      )
    );

    .swiper-slide {
      @include desktop(
        (
          'width': initial,
        )
      );
    }

    .swiper-scrollbar {
      @include desktop(
        (
          'position': static,
          'width': initial,

          'margin': 32px 20px 0,

          'background': get-theme-color('input'),
          'overflow': hidden,
        )
      );
    }

    .swiper-scrollbar-drag {
      @include desktop(
        (
          'background': rgba(get-theme-color('tabs-text'), 0.3),
        )
      );

      &:nth-child(1):not(:last-child) {
        display: none;
      }
    }
  }
}

.menu {
  @include desktop(
    (
      'flex-shrink': 0,
      'position': relative,

      'margin': 0 20px,
      'padding-left': 48px,
      'padding-top': 2px,

      'height': 46px,
      'max-width': fit-content,
    )
  );

  img {
    @include desktop(
      (
        'position': absolute,

        'top': 0,
        'left': 0,

        'width': 36px,
      )
    );
  }

  &__link {
    @include link();

    @include desktop(
      (
        'display': block,

        'margin-top': 5px,
        'max-width': fit-content,

        'font-size': 12px,
      )
    );
  }
}
