
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'height': 600px,
    )
  );

  @include mobile(
    (
      'height': calc(100vw - 40px),
    )
  );
}
