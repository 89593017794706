.marker-pin-object {
    width: 46px;
    height: 60px;
    border-radius: 50% 50% 50% 0;
    /* background: #4d516c; */
    position: absolute;
    /* transform: rotate(-45deg); */
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    display: flex;
  }
  
  /* .marker-pin-in {
    width: 24px;
    height: 24px;
    background-color: aliceblue;
    border-radius: 50%;
    margin: auto;
  } */
  
  .marcer-number {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    position: absolute;
    top: -19px;
    right: -46px;
    /* background-color: blue; */
    color: antiquewhite;
    border-radius: 8px;
    padding: 3px;
    min-width: 110px;
  }
  
  .marker-pin-restaurant {
    /* width: 30px;
    height: 30px; */
    border-radius: 50% 50% 50% 0;
    /* background: #26b91c; */
    position: absolute;
    /* transform: rotate(-45deg); */
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    display: flex;
  }
  
  .selected {
    background-color: rgb(231, 46, 62);
    position: absolute;
    top: -73px;
    left: -6px;
    border-radius: 12px;
    padding: 5px;
    color: white;
    text-align: center;
  }

  .triangle {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: inherit;
    left: 22px;
    transform: rotate(45deg);
    top: 54px;
  }

  .status {
    background-color: rgb(231, 46, 62);
    position: absolute;
    top: -15px;
    left: 17px;
    border-radius: 5px;
    padding: 2px;
    color: white;
    max-width: 74px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status:hover {
    max-width: 300px;
    border: solid 1px  rgb(88, 16, 22);;
  }