
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  :global .loader-container {
    .loader .spinner {
      @include desktop(
        (
          'width': 40px,
          'height': 40px,

          'border-width': 5px,
          'border-top-color': get-theme-color('primary'),
          'animation-duration': 1.3s,
        )
      );
    }
  }
}
