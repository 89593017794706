
  
  .legend_list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .legend_circle {
    height: 10px;
    width: 10px;
    border-radius: 20px;
  }
  
  .legend_circle_blue {
    background-color: rgb(9, 114, 225);
  }
  
  .legend_circle_foodPice {
    background-color: #4B0082;
  }
  