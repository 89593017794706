.wrapper {
  /* border-radius: 20px;
  background-color: bisque;
  cursor: pointer;
  padding: 5px; */
  display: flex;
  flex-wrap: wrap;
  /* height: auto; */
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  /* border-color: #d9d9d9; */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  /* border-style: dashed; */
  font-size: 14px;
    /* height: 32px; */
    padding: 4px 15px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px dashed #d9d9d9;
}
