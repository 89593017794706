// breakpoints
$grid-breakpoints: (
  'lg': 1280px,
  'xs': 650px,
);

$theme-fonts: (
  'default': 'gotham',
);

$theme-colors: (
  'default': #252527,
  'bg': #f4f5fb,
  'bg-dark': #23384f,

  'input': #f2f3f9,

  'tabs-text': #4d516c,
  'tabs-bg': #f2f3f9,

  'error': #c82929,
  'success': #26b91c,
  'primary': #4a59a9,
  'link': #2847c1,
  'overlay': rgba(0, 0, 0, 0.2),
);

$theme-gradients: (
  'primary': linear-gradient(98.14deg, #7acd47 7.55%, #67b437 89.96%),
  'whatsapp': linear-gradient(129.9deg, #43d62b 13.98%, #26b91c 84.54%),
  'telegram':
    linear-gradient(154.15deg, rgba(107, 191, 236, 0.7) 1.86%, #21a0e1 126.15%),
  'viber': linear-gradient(180deg, #9b64bf 0%, #7f4da0 100%),
);

$input-radius: 2px;
$button-radius: $input-radius;
$tabs-radius: $input-radius;

$droplist-shadow: 0px 10px 25px rgba(32, 33, 37, 0.1);

$transition-time: 0.3s;
