.wrapper {
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input__container {
  display: flex;
  flex-direction: row;
}

.input__clear {
  margin-left: -26px;
  z-index: 1;
  background-color: #f0f8ff;
  border-radius: 50px;
  border: 1px solid rgb(217, 217, 217);
  height: 21px;
  width: 20px;  
  align-self: center;
}