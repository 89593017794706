
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include card;

  @include desktop(
    (
      'position': relative,
    )
  );

  :global .separator {
    @include desktop(
      (
        'margin': 32px 0,
      )
    );
  }
}

.link {
  @include link;

  @include desktop(
    (
      'position': absolute,

      'top': 40px,
      'right': 40px,
    )
  );
}
