
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.row {
  @include desktop(
    (
      'position': relative,
      'display': flex,
    )
  );
}

.select {
  @include desktop(
    (
      'flex-grow': 1,
      'width': 220px,
      'margin-right': 10px,
    )
  );
}

.input {
  @include desktop(
    (
      'flex-shrink': 0,
      'width': 120px,

      'text-align': center,
    )
  );
}

.burgerButton {
  @include desktop(
    (
      'position': absolute,

      'top': 0,
      'right': 0,
    )
  );
}

.droplist {
  @include desktop(
    (
      'position': absolute,

      'top': 20px,
      'right': 0,
    )
  );
}
