
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
// .container {  
//     @include desktop(
//       (
//         'margin-top': 20px,
//         'height': 600px,
//       )
//     );
  
//     @include mobile(
//       (
//         'height': calc(100vw - 40px),
//       )
//     );
//   }

.container {  
  @include desktop(
    (
      'margin-top': 5px,
      'height': calc(100vh - 90px),
    )
  );

  @include mobile(
    (
      'height': calc(100vh - 40px),
    )
  );
}

  