
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {    
    @include card;    
}

.row {
  @include desktop(
    (
      'display': flex,
      'align-items': flex-end,

      'margin': 0 -10px,
    )
  );
}

.search {
  @include desktop(
    (
      'position': relative,

      'flex-grow': 1,
      'margin': 0 10px,
    )
  );

  &__droplist {
    @include desktop(
      (
        'width': 100%,
      )
    );
  }
}

.button {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'justify-content': center,

      'flex-shrink': 0,

      'margin': 0 10px,

      'width': 60px,
      'height': 60px,

      'background': get-theme-color('input'),
      'border-radius': $input-radius,
    )
  );

  svg {
    @include desktop(
      (
        'max-width': 20px,
        'max-height': 20px,

        'fill': get-theme-color('tabs-text'),
      )
    );
  }
}
