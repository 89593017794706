
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.title {
  @include h1;
}

.card,
.map {
  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}
