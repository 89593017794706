
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
textarea {
  border: none;
  background: none;
  box-shadow: none;
  resize: none;
  outline: none;
}

.fluid {
  @include input;

  @include desktop(
    (
      'padding-top': 12px,
      'padding-bottom': 12px,
      'height': 100px,
    )
  );
}
