
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
// @value someClass from "./xxx.module.css";
// @import '../../../Control/Control.module.scss';

.container {
  @include desktop(
    (
      'position': relative,
    )
  );

  // .top {
  //   @include desktop(
  //     (
  //       'padding-right': 32px,
  //     )
  //   );
  // }
}

.burgerButton {
  @include desktop(
    (
      'position': absolute,

      'top': 0,
      'right': 0,
    )
  );
}

.droplist {
  @include desktop(
    (
      'position': absolute,

      'top': 20px,
      'right': 0,
    )
  );
}
