
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include card;

  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}

.title {
  @include h1;
}

.hall {
  @include desktop(
    (
      'margin-top': 32px,
    )
  );

  @include mobile(
    (
      'margin-top': 20px,
    )
  );

  &__gallery {
    @include desktop(
      (
        'position': relative,
      )
    );

    :global .swiper {
      @include desktop(
        (
          'margin': 0 -10px,
        )
      );
    }

    :global .swiper-slide {
      @include desktop(
        (
          'width': 380px,
          'margin': 0 10px,
        )
      );

      @include mobile(
        (
          'width': calc(100vw - 80px),
        )
      );
    }

    img {
      @include desktop(
        (
          'height': 260px,
          'width': 100%,
        )
      );

      @include mobile(
        (
          'height': initial,
        )
      );
    }
  }

  &__sliderArrow {
    @include desktop(
      (
        'position': absolute,

        'top': 50%,
        'margin-top': -30px,
      )
    );

    &[disabled] {
      display: none;
    }

    &[data-direction='prev'] {
      @include desktop(
        (
          'left': -30px,
        )
      );
    }

    &[data-direction='next'] {
      @include desktop(
        (
          'right': -30px,
        )
      );
    }
  }

  &__list {
    @include desktop(
      (
        'display': flex,
        'margin': 0 -16px,
      )
    );

    :global li {
      @include desktop(
        (
          'margin': 16px 16px 0,
        )
      );
    }
  }

  &__comment {
    @include desktop(
      (
        'margin-top': 16px,
      )
    );
  }
}
