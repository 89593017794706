
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include card;

  @include desktop(
    (
      'position': relative,
    )
  );

  :global .separator {
    @include desktop(
      (
        'margin': 24px 0,
      )
    );

    @include mobile(
      (
        'margin': 16px 0,
      )
    );
  }
}

.row {
  @include desktop(
    (
      'display': flex,
      'margin': 0 -20px,
    )
  );

  @include mobile(
    (
      'display': block,
    )
  );
}

.preview {
  @include desktop(
    (
      'margin': 0 20px,
      'width': 443px,

      'background-size': cover,
      'background-position': center,
    )
  );

  @include mobile(
    (
      'width': initial,
      'padding-top': 55%,
    )
  );

  @include at-root('a') {
    display: block;
  }
}

.info {
  @include desktop(
    (
      'margin': 0 20px,
      'width': 607px,
    )
  );

  @include mobile(
    (
      'margin-top': 20px,
      'width': initial,
    )
  );
}

.title {
  @include h2;

  @include desktop(
    (
      'color': inherit,
    )
  );

  @include at-root('a') {
    &:hover {
      color: get-theme-color('primary');
    }
  }
}

.feature {
  @include desktop(
    (
      'margin-top': 16px,

      'display': flex,
      'align-items': center,
    )
  );

  &__icon {
    @include desktop(
      (
        'width': 24px,
        'height': 24px,

        'margin-right': 8px,
      )
    );
  }
}

.actions {
  @include desktop(
    (
      'display': flex,
      'margin': 0 -16px,
    )
  );

  button,
  a {
    @include desktop(
      (
        'margin': 0 16px,
      )
    );

    &:nth-child(1) {
      @include link;
    }

    &:nth-child(2) {
      @include link(get-theme-color('error'));
    }
  }
}

.list {
  @include list($mt: 8px);

  @include desktop(
    (
      'display': flex,
      'flex-wrap': wrap,

      'margin': -8px -16px 0,
    )
  );

  &__item {
    @include desktop(
      (
        'margin': 8px 16px 0,
      )
    );
  }
}

.sortButton {
  $self: &;

  @include desktop(
    (
      'position': absolute,
      'z-index': 2,

      'left': 50%,
      'margin-left': -16px,

      'width': 32px,
      'height': 32px,

      'background': get-theme-color('input'),
      'border': 4px solid #fff,
      'border-radius': 50%,

      'opacity': 0,
      'visibility': hidden,
    )
  );

  &:nth-child(1) {
    @include desktop(
      (
        'top': -10px,

        'transform': scaleY(-1),
      )
    );
  }

  &:nth-child(2) {
    @include desktop(
      (
        'bottom': -10px,
      )
    );
  }

  @debug $self;

  .container:hover & {
    visibility: visible;
    opacity: 1;
  }

  .container:first-child &:first-child {
    display: none;
  }

  .container:last-child &:last-child {
    display: none;
  }

  &:hover {
    background: get-theme-color('primary');

    svg {
      fill: #fff;
    }
  }

  svg {
    @include desktop(
      (
        'width': 8px,
        'height': 100%,

        'fill': get-theme-color('tabs-text'),
        'transition': inherit,
      )
    );
  }
}
