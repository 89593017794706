
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'font-size': 16px,
    )
  );

  @include mobile(
    (
      'font-size': 14px,
    )
  );

  :global .separator {
    @include desktop(
      (
        'margin': 32px 0,
      )
    );

    @include mobile(
      (
        'margin': 20px 0,
      )
    );
  }
}

.map {
  @include desktop(
    (
      'margin-top': 20px,
    )
  );
}
