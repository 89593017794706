
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.item {
  @include desktop(
    (
      'position': relative,

      'overflow': hidden,
    )
  );

  &:hover {
    .deleteButton {
      transform: translateX(0);
    }
  }
}

.deleteButton {
  @include desktop(
    (
      'position': absolute,

      'top': 0,
      'right': 0,

      'width': 60px,
      'height': 60px,

      'background': get-theme-color('error'),
      'text-align': center,

      'cursor': pointer,
      'transition': $transition-time,
      'transform': translateX(100%),
    )
  );

  svg {
    @include desktop(
      (
        'width': 17px,
        'height': 100%,

        'fill': #fff,
      )
    );
  }
}
