
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'position': fixed,
      'z-index': 50,

      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
    )
  );
}

.overlay {
  @include desktop(
    (
      'position': absolute,
      'z-index': -1,

      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,

      'background': get-theme-color('overlay'),
    )
  );
}
