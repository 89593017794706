
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.container {
  @include desktop(
    (
      'position': absolute,
      'z-index': 5,

      'padding': 10px 0,
      'max-height': 250px,
      'overflow-y': auto,

      'background': #fff,
      'box-shadow': $droplist-shadow,
    )
  );

  &:global:not(._visible) {
    height: 0;
    padding: 0;
  }
}

.item {
  @include desktop(
    (
      'padding': 6px 16px,

      'font-size': 12px,

      'cursor': pointer,
      'transition': $transition-time,
    )
  );

  a {
    color: inherit;
  }

  &:hover {
    background: get-theme-color('bg');
  }

  &:global._selected {
    background: get-theme-color('bg');
  }

  &:global._red {
    color: get-theme-color('error');
  }
}
