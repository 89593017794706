
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.admin-form-contacts {
  &__item {
    @include desktop(
      (
        'position': relative,
      )
    );
  }

  &__item-button {
    @include desktop(
      (
        'position': absolute,

        'top': 0,
        'right': 0,
      )
    );
  }

  &__item-droplist {
    @include desktop(
      (
        'position': absolute,

        'top': 20px,
        'right': 0,
      )
    );
  }

  &__input {
    &:nth-child(3) {
      .input__top {
        @include desktop(
          (
            'padding-right': 32px,
          )
        );
      }
    }
  }
}
