
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.text {
  @include desktop(
    (
      'line-height': 1.4,
    )
  );
}

.showComment {
  @include link;

  @include desktop(
    (
      'max-width': fit-content,
      'margin-top': 8px,

      'font-size': 12px,
    )
  );
}
