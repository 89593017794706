
          @import "src/assets/scss/helpers/variables.scss";
          @import "src/assets/scss/helpers/functions.scss";
          @import "src/assets/scss/helpers/mixins.scss";
          @import "src/assets/scss/helpers/extends.scss";
        
.dotted-button {
  @include desktop(
    (
      'display': flex,
      'align-items': center,
      'justify-content': space-between,

      'padding': 0,

      'width': 20px,
      'height': 20px,
    )
  );

  span {
    @include desktop(
      (
        'display': block,

        'width': 4px,
        'height': 4px,

        'background': get-theme-color('default'),
        'border-radius': 50%,

        'transition': inherit,
      )
    );
  }

  &:hover {
    span {
      background: get-theme-color('primary');
    }
  }
}
